import React, { useState, useEffect } from "react";
import { ApproveSubscription, GetPlans, SelectPlan, CheckSubscription, CancelSubscription, RegisterUserByShop } from "../components/auth";
import Spinner from "../components/spinner/Spinner"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getItem, getTempUserData, getUserData, removeItem, setItem, setTempUserData, setUserData, subscriptionPlanTransformData } from "../components/utils";
import { toast } from "react-toastify";
import Header from "../components/Header";
import Footer from "../components/FooterNew";
import RegistrationTabs from "../components/RegistrationTabs";
import NavLinks from "../components/Nav";
import { Box, Grid, Stack, Typography, darken, useMediaQuery, Button as MUIButton, Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { userDetail } from "../features/userSlice";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ConfirmFreePlan from "./ConfirmFreePlan";

const memberShipLevel = ["Platinum", "Free"]

const Service = ({ noNavLinks, isSelectPlan }) => {
  const [searchParams] = useSearchParams();
  const [Loader, setLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const [currentPlanRes, setCurrentPlanRes] = useState({});
  const [currentMemberShipLevel, setCurrentMemberShipLevel] = useState(memberShipLevel?.[1]);
  const [subscription, setSubscription] = useState(null)
  const [openDialog, setOpenDialog] = useState({ status: false, data: {}, path: "" });

  var subscriptionId = getItem('subscription_id');
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:500px)');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopName = queryParams.get("shop");
  const emailRequired = queryParams.get("emailRequired") || "false";
  const from = queryParams.get("from");
  const host = queryParams.get("host");

  useEffect(() => {
    let LoggedIn = getUserData();
    if (LoggedIn && LoggedIn != "" && isSelectPlan) {
      removeItem("tempUserData")
      return navigate("/");
    }
  }, [])

  useEffect(() => {
    const charge_id = searchParams.get('charge_id')
    if (charge_id && subscriptionId) {
      approveSubscription(charge_id)
    }

  }, [searchParams, subscriptionId])

  const getCurrentSubscription = (isUseTempUser = false) => {
    CheckSubscription(isUseTempUser)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const dataUpdate = [{ ...res?.data?.plan_info }]
          setCurrentPlanRes(res?.data?.plan_info)
          if (plans.length == 0) {
            GetPlans(isUseTempUser)
              .then((res) => {
                if (res.status == 200) {
                  const getTransFormData = subscriptionPlanTransformData(res.data);
                  setPlans(getTransFormData);
                  let findPlanInfo
                  if (!dataUpdate?.[dataUpdate.length - 1]?.plan_name || dataUpdate?.[dataUpdate.length - 1]?.plan_name == "") {
                    if (dataUpdate?.[dataUpdate.length - 1].is_free == 1) {
                      findPlanInfo = { ...getTransFormData?.[getTransFormData.length - 1], is_free: 1 }
                    } else {
                      findPlanInfo = getTransFormData.find((info) => info?.price == dataUpdate?.[dataUpdate.length - 1]?.price)
                    }
                  } else {
                    findPlanInfo = getTransFormData.find((info) => info?.name == dataUpdate?.[dataUpdate.length - 1]?.plan_name)
                  }
                  setCurrentMemberShipLevel(res?.data?.plan_info?.is_free == 0 ? memberShipLevel[0] : memberShipLevel[1])
                  setCurrentPlan(findPlanInfo)
                }
              })
          } else {
            let findPlanInfo
            if (!dataUpdate?.[dataUpdate.length - 1]?.plan_name || dataUpdate?.[dataUpdate.length - 1]?.plan_name == "") {
              if (dataUpdate?.[dataUpdate.length - 1].is_free == 1) {
                findPlanInfo = { ...plans?.[plans.length - 1], is_free: 1 }
              } else {
                findPlanInfo = plans.find((info) => info?.price == dataUpdate?.[dataUpdate.length - 1]?.price)
              }
            } else {
              findPlanInfo = plans.find((info) => info?.name == dataUpdate?.[dataUpdate.length - 1]?.plan_name)
            }
            setCurrentMemberShipLevel(res?.data?.plan_info?.is_free == 0 ? memberShipLevel[0] : memberShipLevel[1])
            setCurrentPlan(findPlanInfo)
          }
          setSubscription(res.data);
        } else {
          toast.error(res.message);
        }
      }).catch((err) => {
      })
  }

  const approveSubscription = (charge_id) => {
    setLoader(true);
    const payload = {
      charge_id: charge_id,
      subscription_id: subscriptionId
    }
    ApproveSubscription(payload)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          navigate("/account-detail");
          toast.success(res.message);
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      }).catch((err) => {
        setLoader(false);
        toast.error(err.message);
      })
  }

  const getAllPlans = (isUseTempUser = false) => {
    setLoader(true);
    GetPlans(isUseTempUser)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          const getTransFormData = subscriptionPlanTransformData(res.data);
          setPlans(getTransFormData);
          setCurrentPlan(getTransFormData?.[1])
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      }).catch((err) => {
        setLoader(false);
        toast.error(err.message);
      })
  }

  const callDispatchGetTempUserAndPlans = (bodyData) => {
    RegisterUserByShop(bodyData).then((res) => {
      if (res.status === 200) {
        const userData = res.data
        setTempUserData(userData)
        getAllPlans(true)
        getCurrentSubscription(true)
      }
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  useEffect(() => {
    if (shopName && isSelectPlan) {
      const tempUser = getTempUserData()
      // eslint-disable-next-line eqeqeq
      if (emailRequired != "true" && !tempUser) {
        const bodyData = {
          shop: shopName,
          from: from ? from : host ? 'client' : null,
          email: null,
        }
        callDispatchGetTempUserAndPlans(bodyData)
      } else {
        getAllPlans(true)
        getCurrentSubscription(true)
      }
    } else {
      getAllPlans()
      getCurrentSubscription()
    }
  }, [shopName, isSelectPlan]);

  const cancelPlan = (plan) => {
    CancelSubscription({})
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // setLoader(false);
          toast.success(res.message);
          setSubscription(null);
          getCurrentSubscription()
          // getAllPlans()
        } else {
          // setLoader(false);
          toast.error(res.message);
        }
      }).catch((err) => {
        // setLoader(false);
        toast.error(err.message);
      })
  }

  const url = window.location.href;
  const urlWithoutQueryParams = url.split('?')[0];

  const selectPlan = (plan) => {
    if (plan.is_free === 1 && (subscription.plan_info.id !== plan.plan_id && subscription.plan_info.id !== currentPlanRes.id)) {
      cancelPlan(plan);
      setOpenDialog({ status: false, data: {}, path: "" })
      return;
    }
    setLoader(true);
    const payload = {
      plan_name: plan.name,
      plan_id: plan.plan_id,
      callback_url: urlWithoutQueryParams,
      isFree: currentMemberShipLevel == "Free" ? true : false
    }
    SelectPlan(payload, isSelectPlan ? true : false)
      .then((res) => {
        if (res.status == 200) {
          if (currentMemberShipLevel == "Free" && isSelectPlan) {
            navigate(`/signup?shop=${shopName}${from ? `&from=${from}` : ""}`);
            toast.success(res.message);
            setOpenDialog({ status: false, data: {}, path: "" })
            return;
          }

          if (currentMemberShipLevel == "Free") {
            navigate(`/account-detail#subscription-details`);
            toast.success(res.message);
            setOpenDialog({ status: false, data: {}, path: "" })
            return;
          }
          setLoader(false);
          setItem('subscription_id', res.data.subscription_id)
          toast.success(res.message);
          window.open(res.data.confirm_url, "_self")
          setOpenDialog({ status: false, data: {}, path: "" })

        } else {
          setLoader(false);
          toast.error(res.message);
          setOpenDialog({ status: false, data: {}, path: "" })
        }
      }).catch((err) => {
        setLoader(false);
        toast.error(err.message);
        setOpenDialog({ status: false, data: {}, path: "" })
      })
  }

  const isPlans = plans.length > 0;

  const [isOpenGetEmailPopOver, setIsOpenGetEmailPopOver] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const checkIsEmailRequired = isSelectPlan && emailRequired == "true"
    if (checkIsEmailRequired) {
      setIsOpenGetEmailPopOver(true);
    }
  }, [isSelectPlan, emailRequired])

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmitEmail = (data) => {
    const bodyData = {
      shop: shopName,
      from: from ? from : host ? 'client' : null,
      email: data.email,
    }
    callDispatchGetTempUserAndPlans(bodyData)
    setIsOpenGetEmailPopOver(false)
  }

  const handleChangePlan = (e, plan) => {
    setCurrentPlan(plan);
  };

  return (
    <>
      <section className="bt_batch_overview py-0" >
        <div className="container-fluid px-0" >
          <div className="row g-0">
            <div className="col-12 d-flex">
              {noNavLinks ? "" : <NavLinks />}
              <div className="">
                <Stack className="container-fluid" sx={{
                  height: isPlans ? "auto" : "210px !important",
                  marginLeft: {
                    xs: (!isSelectPlan || !noNavLinks) ? "100px" : 0,
                    sm: (!isSelectPlan || !noNavLinks) ? "100px" : 0,
                    md: (!isSelectPlan || !noNavLinks) ? "100px" : 0,
                    lg: 0,
                    xl: 0,
                  },
                  padding: {
                    xs: (isSelectPlan || noNavLinks) ? 0 : "unset",
                    sm: (isSelectPlan || noNavLinks) ? 0 : "unset",
                    md: (isSelectPlan || noNavLinks) ? 0 : "unset",
                    lg: "unset",
                    xl: "unset",
                  }
                }}>
                  <div className="row g-0 x">
                    <div className="p-4" >
                      <div className="mt-2" style={{ marginBottom: "200px" }}>
                        <Typography variant="h3" sx={{ fontSize: 28, fontWeight: 800, color: "#7f7f7f" }}>Collections & Membership</Typography>
                        <Typography variant="h5" sx={{ fontSize: 22, mt: 2, color: "#5c715f" }}>1. Subscribe to a collection</Typography>
                        <Typography variant="h5" sx={{ fontSize: 22, mt: 2, color: "#5c715f" }}>2. Select Freemium or Platinum Membership</Typography>
                      </div>
                      {(plans.length === 0 && !isOpenGetEmailPopOver) ?
                        (
                          <div className="spinner_div">
                            <Spinner />
                          </div>
                        )
                        : (
                          <>
                            <Grid container spacing={2}>
                              {plans.filter((d) => d.price !== 0).map((plan, index) => (
                                <Grid item xs={6} sm={6} md={6} lg={4} xl={4}
                                  key={index}
                                  id={index}
                                  sx={{
                                    flex: { xs: '1 1 100%', sm: `1 1 calc(50% - 16px)`, md: `1 1 calc(25% - 16px)` },
                                    mb: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: {
                                      xs: (index == 1) ? "100px" : (index == 2) ? "55px" : (index == 3) ? "160px" : 0,
                                      sm: (index == 1) ? "100px" : (index == 2) ? "55px" : (index == 3) ? "160px" : 0,
                                      md: (index == 1) ? "100px" : (index == 2) ? "55px" : (index == 3) ? "160px" : 0,
                                      lg: 0,
                                      xl: 0,
                                    }
                                  }}
                                >
                                  <Box
                                    className="package-item service-plan-package-item">
                                    <Box className="imgDiv" sx={{
                                      mb: 2,
                                      height: 70,
                                      borderRadius: '70%',
                                      backgroundColor: 'white',
                                      border: '1px solid #E6E6E6',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}>
                                      <img src={`${plan.img}`} alt="logo" className="service-plan-img" />
                                    </Box>
                                    <Box>
                                      <input
                                        className="form-check-input form-check-input-radio-service"
                                        defaultChecked={plan?.id == currentPlan?.id}
                                        checked={
                                          plan?.id == currentPlan?.id
                                        }
                                        value={plan?.id}
                                        type="radio"
                                        name={`planRadio`}
                                        id={`planFlexRadioDefault-${index}`}
                                        onChange={(e) =>
                                          handleChangePlan(e, plan)
                                        }
                                      // style={{
                                      //   width: "2.5rem !important",
                                      //   height: "2.5rem !important",
                                      //   marginTop: "50% !important",
                                      // }}
                                      />
                                    </Box>
                                    <Typography variant="h1" className="service-plan-package-name">
                                      {plan.name}
                                    </Typography>
                                  </Box>
                                  <Box className="d-flex align-items-center justify-content-center service-plan-package-label" >
                                    {`${plan.is_free == 0 ? 'Free + ' : ''}${plan.connection_limit} ${plan.is_free == 0 ? 'Connections' : 'Sample Connection'}`}
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                    </div>
                    {plans.length !== 0 && (
                      <>
                        <div style={{ background: "#f8f5ef", color: "#6b7d62", padding: "20px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={4} sm={5} md={3} lg={3} xl={3}>
                              <Typography variant="h6" className="select-label-service">Your Collection Subscription:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                              <select id="plan-collection-sub" className="select-service" onChange={(e) => {
                                const checkData = plans.find(d => d?.id == e.target.value) || {}
                                setCurrentPlan(checkData)
                              }} value={currentPlan?.id} >
                                {plans.filter((d) => d?.price !== 0).map(data => (
                                  <option value={data?.id}>{data?.name}</option>
                                ))}
                              </select>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={4} sm={5} md={3} lg={3} xl={3}>
                              <Typography variant="h6" className="select-label-service">Your Membership Level:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                              <select id="plan-membership-level" className="select-service"
                                onChange={(e) => {
                                  setCurrentMemberShipLevel(e.target.value)
                                }} value={currentMemberShipLevel} >
                                {memberShipLevel?.map(data => (
                                  <option value={data}>{data}</option>
                                ))}
                              </select>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={4} sm={5} md={3} lg={3} xl={3}>
                              <Typography variant="h6" className="select-label-service">Monthly fee</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                              <Typography variant="h2" sx={{ fontSize: 18, color: '#6b7d62' }}>{`$${currentMemberShipLevel == "Free" ? 0 : currentPlan?.price}/mo`}</Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <MUIButton
                          variant="contained"
                          type="submit"
                          name="join"
                          sx={{
                            mt: 4,
                            mr: 4,
                            mb: 2,
                            ml: 'auto',
                            padding: "8px",
                            color: 'white',
                            backgroundColor: '#B78580',
                            width: isMobile ? '100%' : '150px !important',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: darken('#B78580', 0.1),
                              boxShadow: 'none',
                            },
                          }}
                          // selectPlan(currentPlan)
                          onClick={() => {
                            if (currentMemberShipLevel != "Free") {
                              selectPlan(currentPlan)
                            } else {
                              setOpenDialog({ status: true, data: currentPlan, path: location.pathname + location.search })
                            }
                          }}
                          endIcon={<ArrowForwardIcon sx={{ color: 'white', fontWeight: 600 }} />}
                        >
                          Next
                        </MUIButton>
                      </>
                    )}
                  </div>
                </Stack>
              </div>
              {/* <Footer/> */}
            </div>
          </div>
        </div>
      </section>
      <Modal show={isOpenGetEmailPopOver}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Email Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-container">
            <form>
              <div className="field-container">
                <label htmlFor="email">Email</label>
                <input id="email" type="email" placeholder=""
                  {...register("email", { required: true })}
                />
                {errors.email?.type === 'required' && <p role="alert" className="invalid-feedback">Email is required</p>}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start flex-row">
          <div className="flex-row">
            <div className="custom_cx_button ">
              <div className="combine_btn_colm float-start">
                <Button
                  className="combine_button mt-0"
                  name="combine"
                  variant="primary"
                  onClick={handleSubmit(onSubmitEmail)}
                > Submit
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Dialog
        fullScreen
        open={openDialog.status}
        onClose={() => { }}
      >
        <ConfirmFreePlan selectPlan={selectPlan} openDialog={openDialog} setOpenDialog={setOpenDialog} />
      </Dialog>
    </>
  );
};

export default Service;
