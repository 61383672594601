import React, {useState, useRef, useEffect} from "react";
import {useForm} from "react-hook-form";
import useInputMask from '../hooks/useInputMask';

import {
  captureOrderPayment
} from "../components/auth";
import Spinner from "./spinner/Spinner";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const PaypalAdvanceCheckout = ({cartDetails,cartId, totalAmount, orderIds, paypalAdvanceCheckoutObj, customerEmail, remainingAmount}) => {

  const {register, handleSubmit, watch, formState: {errors}} = useForm();
  useInputMask();
  const [cardFrom, setCardForm] = useState({
    name: "",
    cvv: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: ""
  });
  const [processing, setProcessing] = useState(false);
  let navigate = useNavigate();

  // const onSubmit = data => console.log(data);
  console.log("HERE");
  console.log(paypalAdvanceCheckoutObj);
  const onSubmit = (data) => {
    const payload = {
      creditCardInformation: data,
      orderInformation: orderIds,
      orderInfo: paypalAdvanceCheckoutObj,
      email: customerEmail,
    }
    setProcessing(true);
    captureOrderPayment(payload)
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            setProcessing(false);
            toast.success(response.message);
            navigate("/");
          }, 5000);
        } else {
          setTimeout(() => {
            setProcessing(false);
            toast.error(response.message);  
            navigate("/");
          }, 5000);
        }
      }).catch(errors => {
      setTimeout(() => {
        setProcessing(false);
        toast.error(errors);
        // navigate("/");
      }, 5000);
      console.log(errors);
    })
  }

  return (
    <>
      <div className="form-container" style={{marginTop: "20px"}}>
        <form onSubmit={handleSubmit(onSubmit)}>
​
          <div className="field-container">
            <label htmlFor="name">Name</label>
            <input id="name" type="text" placeholder="Card Holder's Name"
                   {...register("name", {required: true})}
            />
            {errors.name?.type === 'required' && <p role="alert" className="invalid-feedback">Name is required</p>}
​
          </div>
          <div className="field-container">
            <label htmlFor="cardNumber">Card Number</label>
            <input id="cardNumber" type="text" placeholder="Card Number" data-inputmask-regex="[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"
                   {...register("cardNumber", {
                     required: true
                   })}
            />
            {errors.cardNumber?.type === 'required' &&
              <p role="alert" className="invalid-feedback">Card Number is required</p>}
          </div>
          <div className="field-container">
            <label htmlFor="expiryMonth">Expiration Month</label>
            <input id="expiryMonth" type="text" data-inputmask-regex="[0-1][0-9]" placeholder="MM"
                   {...register("expiryMonth", {required: true, max:12})}
            />
            {errors.expiryMonth?.type === 'required' &&
              <p role="alert" className="invalid-feedback">Expiration Month is required</p>}
​
          </div>
          <div className="field-container">
            <label htmlFor="expiryYear">Expiration Year</label>
            <input id="expiryYear" type="text" data-inputmask-regex="[0-9][0-9][0-9][0-9]" placeholder="YYYY"
                   {...register("expiryYear", {required: true, valueAsNumber: true})}
            />
            {errors.expiryYear?.type === 'required' &&
              <p role="alert" className="invalid-feedback">Expiration Year is required</p>}
​
          </div>
          <div className="field-container">
            <label htmlFor="cvv">Security Code</label>
            <input id="cvv" type="text" data-inputmask-regex="[0-9][0-9][0-9]" placeholder="CVV"
                   {...register("cvv", {required: true, valueAsNumber: true})}
            />
            {errors.cvv?.type === 'required' &&
              <p role="alert" className="invalid-feedback">Security Code is required</p>}
​
          </div>
​
          <div className="row gx-3">
            <div className="col-md-12">
              <div className="button_session custom_cx_button">
                {processing ? (
                  <div className="spinner_div">
                    <Spinner/>
                  </div>
                ) : (
                  <div className="combine_btn_colm strip_payment_btn">
                    <button
                      type="submit"
                      className="combine_button"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   payNowUsingCreditCard();
                      // }}
                      name="combine"
                    >
                      Pay ${(Number(totalAmount) - Number(remainingAmount || 0)).toFixed(2)}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/*<a className="go_to_save_card"*/}
          {/*   onClick={() => payNowUsingCreditCard()}*/}
          {/*>Pay Now {totalAmount}</a>*/}
        </form>
      </div>
    </>
  );
};
export default PaypalAdvanceCheckout;

